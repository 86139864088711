import * as http from '../http'

export default {
  //发送短信列表
  querySmsList: params => {
    return http.post('/sms/list', params)
  },
  //短信剩余条数
  querySmsCount: params => {
    return http.post('/sms/count', params)
  },
  queryBusinessTemplateList: params => {
    return http.post('/message-business-template/list', params)
  },
  addBusinessTemplate: params => {
    return http.post('/message-business-template/add', params)
  },
  editBusinessTemplate: params => {
    return http.post('/message-business-template/edit', params)
  },
  delBusinessTemplate: params => {
    return http.post('/message-business-template/delete', params)
  },
  setStatusData: params => {
    return http.post('/message-business-template/edit-status', params)
  },
  syncAll: params => {
    return http.post('/message-business-template/sync-all', params)
  }
}
