<template>
  <el-row class="warp">
    <el-col :span="24" v-loading="loading" element-loading-text="拼命加载中">
      <!--工具条-->
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item>
          <el-input v-model="searchForm.mobile" placeholder="手机号" @keyup.enter.native="handleSearch"></el-input>
        </el-form-item>
        <el-form-item label="发送状态" prop="status" label-width="80px">
          <el-select v-model="searchForm.status" placeholder="请选择" @change="handleSearch">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleReset">重置</el-button>
        </el-form-item>
        <el-form-item label="通知短信余额:">
          <div>{{ smsCount }} 条</div>
        </el-form-item>
      </el-form>

      <!--列表-->
      <el-table :data="data" style="width: 100%;">
        <el-table-column type="index" width="60"></el-table-column>
        <el-table-column prop="mobile" label="手机号"></el-table-column>
        <el-table-column prop="status" label="状态" :formatter="statusFormatter"></el-table-column>
        <el-table-column prop="content" label="内容" width="360"></el-table-column>
        <el-table-column prop="createTime" label="发送时间"></el-table-column>
        <el-table-column prop="smsResultCode" label="运营商返回码"></el-table-column>
        <el-table-column prop="smsBatchNumber" label="运营商批次号"></el-table-column>
      </el-table>

      <!--工具条-->
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="10"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        style="float:right;"
      >
      </el-pagination>
    </el-col>
  </el-row>
</template>
<script>
import _ from 'lodash'
import API from '@/api/system/api_message'
import PagesMixins from '@/mixins/pagesMixins'
const searchForm = {
  mobile: null,
  status: null
}
export default {
  name: 'messageSms',
  mixins: [PagesMixins],
  data() {
    return {
      data: [],
      loading: false,
      sels: [], //列表选中列
      searchForm: _.cloneDeep(searchForm),
      statusOptions: [
        {
          value: '0',
          label: '发送失败'
        },
        {
          value: '1',
          label: '提交成功'
        },
        {
          value: '2',
          label: '发送成功'
        }
      ],
      smsCount: 0
    }
  },
  mounted() {
    this.handleSearch()
    this.loadSmsCount()
  },
  methods: {
    statusFormatter(row) {
      switch (row.status) {
        case 0:
          return '发送失败'
        case 1:
          return '提交成功'
        case 2:
          return '发送成功'
      }
    },
    handleSearch() {
      this.total = 0
      this.page = 1
      this.getListData()
    },
    handleReset() {
      this.searchForm = _.cloneDeep(searchForm)
      this.handleSearch()
    },
    getListData() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }
      this.loading = true
      API.querySmsList(params).then(result => {
        this.loading = false
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.data = result.data.list
        } else {
          this.$message.error({ showClose: true, message: result.msg, duration: 2000 })
        }
      })
    },
    async loadSmsCount() {
      let params = {
        type: 1
      }
      let result = await API.querySmsCount(params)
      if (result && result.code === '000000') {
        this.smsCount = result.data
      }
    }
  }
}
</script>
